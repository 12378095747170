<template>
  <div class="achievementMain">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-page-header @back="goBack" ></el-page-header>
      <el-breadcrumb-item >我的班级</el-breadcrumb-item>
      <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
      <el-breadcrumb-item>我的成绩</el-breadcrumb-item>
    </el-breadcrumb>
    <div  v-if="!errorflag">
    <div class="achievement ">
      <div class="bgpurple padd">
        <div class="top">
          <p class="name"><span>{{classesinfo.Season}}{{classesinfo.SubSeason}}</span><span>{{classesinfo.Subject}}</span><span>{{classesinfo.GradeStart}}</span><span>{{classesinfo.ClassType}}</span> </p>
          <p><span>{{classesinfo.Year}} {{classesinfo.Season}}{{classesinfo.SubSeason}}</span><span>第 {{classscorecur.LessonNo}} 讲</span> </p>
        </div>
        <div class="selectmain">
          <el-select v-model="classvalue"
                     placeholder="请选择"
                     @change="changeLesson">
            <el-option v-for="(item,index) in classoptions"
                       :key="index"
                       :label="item.text"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
        <p class="bold">成绩表</p>
      </div>
      <div class="bgfff padd">
        我的分数：<span class="bold">{{classscorecur.Score}}</span>
      </div>
      <div class="bgpurple padd average" v-show="ScoreType=='all' || ScoreType=='avg'">
        <span class="averagetit">平均分数：</span>
        <p>班级：<span class="bold">{{classscorecur.ClassAvg}}</span>校区：<span class="bold">{{classscorecur.CampusAvg}}</span>全市：<span class="bold last">{{classscorecur.CityAvg}}</span></p>
      </div>
      <div class="bgpurple padd average" v-show="ScoreType=='all'">
        <span class="averagetit">排名：</span>
        <p>班级：<span class="bold">{{classscorecur.ClassRanking}}</span>校区：<span class="bold">{{classscorecur.CampusRanking}}</span>全市：<span class="bold last">{{classscorecur.CityRanking}}</span></p>
      </div>
    </div>
    <p class="timetip"><span>*</span> 当前显示平均分和数据基于{{classesinfo.date1}}3点，下次更新时间{{classesinfo.date2}}3点。</p>
    <div class="errorlist"  v-show="errorlist.length>0">
      <p class="bold bgpurple padd">逐题对错</p>
      <ul>
        <li v-for="(pritem,prindex) in errorlist"
            :key="prindex"
            :class="prindex%2==0?'bgfff':'bgpurple'"
            class="padd">
          <p v-for="(item,index) in pritem"
             :key="index">
            {{item.Title}}:<i class="el-icon-check"
               v-if="item.ScoreTof==1"></i><i class="el-icon-close"
               v-else></i>
          </p>
        </li>
      </ul>
    </div>
    <div class="bgpurple record" v-show="ScoreType=='all'">
      <p class="bold">学生成长记录</p>
      <p class="recordtit">{{classesinfo.Season}}{{classesinfo.SubSeason}}{{classesinfo.Subject}}{{classesinfo.GradeStart}}{{classesinfo.ClassType}}</p>
      <LineChart id="AverageStorge"
                 :options="AverageStorge"
                 class="echart"
                 ref="c3"></LineChart>
      <p class="linetips">学员成长轨迹图 <span>（分值代表你超越了多少该组别的同学%）</span></p>
    </div>
        </div>
    <div v-else class="errortips">{{errortips}}</div>
  </div>
</template>

<script>
import LineChart from "../../components/LineChart";
import { get_classes_score } from '@/api/classesinfo'
let seriesdata = [
  {
    // symbolSize: 0,
    name: "全市",
    type: "line",
    color: ["#6f72ff "],
    data: [],
    connectNulls: true,
    type: "line"
  },
  {
    name: "全校",
    type: "line",
    color: ["#ff6f76"],
    data: [],
    connectNulls: true,
    type: "line"
  },
  {
    name: "全班",
    type: "line",
    color: ["#54c8e9"],
    data: [],
    connectNulls: true,
    type: "line"
  }
];
export default {
  name: 'class_achievement',
  components: {
    LineChart
  },
  data () {
    return {
      seriesdata: seriesdata,
      AverageStorge: {},
      classvalue: '',
      yearoptions: [],
      classoptions: [],
      classesinfo: [],
      DBType: '',
      OprId: '',
      activeIndex:'',
      classscore: [],
      errorflag:false,
      errortips:'',
      ScoreType:'',
      name:''
    };
  },
  computed: {
    errorlist () {
      var arr = [];
      this.classscore.forEach(item => {
        if (this.classvalue == item.LessonNo) {
          for (var i = 0; i < item.ScoreQbq.length; i = i + 4) {
            arr.push(item.ScoreQbq.slice(i, i + 4));
          }
        }
      })
      return arr
    },
    classscorecur () {
      let classscorecur = {};
      this.classscore.forEach(item => {
        if (this.classvalue == item.LessonNo) {
          classscorecur = item;
        }
      })
      return classscorecur
    },

  },
  created () {
    var query = sessionStorage.getItem('stu_class_query')? JSON.parse(sessionStorage.getItem('stu_class_query')):{}
    this.DBType = query.DBType || null;
    this.OprId = query.OprId || null;
    this.activeIndex=query.activeIndex || null;
    this.name = query.activeIndex && query.activeIndex == '0' ? '正在上课的班级' : '历史的班级';
    this.getInit()
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    async getInit () {
      let prames = {
        DBType: this.DBType,
        OprId: this.OprId,
      }
      let res = await get_classes_score(prames)
      if (res.flag == '1') {
        this.ScoreType = res.datainfo.ScoreType;
        this.classesinfo = res.datainfo.classesinfo;
        // let lessonarr = res.datainfo.Lessonarr;
        let lessonarr = JSON.parse(JSON.stringify(res.datainfo.Lessonarr));
        lessonarr.sort(function (a, b) { return b - a; });
        this.classoptions = lessonarr.map(item => {
          return {
            text: '第' + item + '讲',
            value: item
          }
        })
        this.classvalue = this.classoptions[0].value;
        this.classscore = res.datainfo.classscore;
        //图表数据渲染
        this.initechart(res.datainfo)
      }else if(res.flag =='2'){
        this.errorflag=true;
        this.errortips=res.msg
      } else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    },
    initechart (datainfo) {
      let lessonarr = datainfo.Lessonarr;
      let last = lessonarr[lessonarr.length - 1]
      let seriesdata = this.seriesdata;
      seriesdata[0].data = datainfo.Cityarr;
      seriesdata[1].data = datainfo.Campusarr;
      seriesdata[2].data = datainfo.Classarr;
      if (last > 1) {
        if (lessonarr.length == 1) {
          lessonarr.unshift('1')
          lessonarr.push(last + 2)
          seriesdata[0].data.unshift('');
          seriesdata[1].data.unshift('');
          seriesdata[2].data.unshift('');
          seriesdata[0].data.push('');
          seriesdata[1].data.push('');
          seriesdata[2].data.push('');
        } else if (lessonarr[0] != 1) {
          lessonarr.unshift('1')
          seriesdata[0].data.unshift('');
          seriesdata[1].data.unshift('');
          seriesdata[2].data.unshift('');
        }
      } else {
        lessonarr.push(last + 2)
        seriesdata[0].data.push('');
        seriesdata[1].data.push('');
        seriesdata[2].data.push('');
      }
      this.AverageStorge = {
        xAxisdata: lessonarr,
        seriesdata: seriesdata,
      };

      setTimeout(() => {
        this.$refs.c3.createChart();
      }, 50);
    },
    changeLesson (value) {
      console.log(value)
    }
  },
}
</script>
<style scoped lang="scss">
.achievementMain {
  font-size: 18px;
  color: #343434;
}
.padd {
  padding: 25px 15px;
}
.achievement {
  border: 1px solid #e7e7e7;
  margin-bottom: 20px;
  position: relative;
  .top {
    text-align: center;
    margin: 20px 0 50px 0;
    span {
      margin: 0 10px;
    }
    .name {
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .average {
    display: flex;
    .averagetit {
      flex: 1;
    }
    p {
      flex: 4.5;
      display: flex;
    }
    span.bold {
      flex: 1;
    }
    span.last {
      margin: 0;
    }
  }
}
.timetip {
  margin: 20px 0 20px 15px;
  span {
    color: #6f72ff;
  }
}
.errorlist {
  border: 1px solid #e7e7e7;
  margin-bottom: 20px;
  li {
    display: flex;
    align-items: center;
    p {
      width: 25%;
    }
  }
  .el-icon-check,
  .el-icon-close {
    font-weight: bold;
    font-size: 20px;
    margin-left: 10px;
  }
  .el-icon-check {
    color: #37c81f;
  }
  .el-icon-close {
    color: #ea1e36;
  }
}
.record {
  border: 1px solid #e7e7e7;
  padding: 25px 15px;
  .recordtit {
    font-size: 16px;
    text-align: center;
  }
  .echart {
    margin: 0 auto;
  }
  .linetips {
    font-size: 14px;
    text-align: center;
    color: #343434;
    span {
      color: #929292;
    }
  }
}
</style>
<style lang="scss">
.selectmain {
  position: absolute;
  right: 30px;
  top: 90px;
  border: 1px solid #ccc;
  border-radius: 30px;
  overflow: hidden;
  .el-input__inner {
    border: none !important;
    width: 120px;
    background: #f3f4f4;
  }
}
</style>